<template>
    <div class="container">
        <header class="jumbotron">

            <b-jumbotron text-variant="dark" header="Edit Tweets">
                <p>Edit Tweeting settings</p>
            </b-jumbotron>

        </header>

        <div class="card card-container text-dark">

            <form name="form" @submit.prevent="createNode">
                <div class="form-group">
                    <label for="username">User ID</label>
                    <input v-model="currentUser.id" disabled type="text" class="form-control" name="artist" />
                </div>

                <div class="form-group">
                    <label for="username">Username</label>
                    <input v-model="currentUser.username" disabled type="text" class="form-control" name="artist" />
                </div>

                <div class="form-group">
                    <label for="username">Address</label>
                    <input v-model="currentUser.address" disabled type="text" class="form-control" name="artist" />
                </div>

                <div class="form-group">
                    <label for="querying">Querying Tweets</label>
                    <input v-model="query" type="text" class="form-control" name="artist" />
                </div>

                <div class="form-group mt-4">
                    <label for="attributes"><b>Query</b></label>
                    <div>A list keywords</div>
                    <AttributesModal @logAttributes="logAttributes"></AttributesModal>
                </div>

                <div v-if="attributes.length > 0">
                    <b-row>
                        <b-col cols="6" v-for="(attribute, index) in attributes" :key="index">
                            <b-card bg-variant="light">
                                <b-button size="sm" variant="light" @click="rmAttribute(index)">
                                    <b-icon icon="trash"></b-icon>
                                </b-button>
                                <div class="text-center">
                                    <p>{{ attribute.value }}</p>
                                </div>
                            </b-card>
                        </b-col>

                    </b-row>
                </div>

                <div class="form-group">
                    <label for="externalUrl">Time</label>
                    <div>Delay in (millisec) between Tweets</div>
                    <input v-model="delay" type="number" class="form-control" name="externalUrl" />
                </div>

                <div class="form-group mt-2">
                    <div><b>Image content</b></div>
                    <div>Add a new Image, can be <b>JPEG, PNG or GIF</b></div>
                    <b-button variant="primary" @click="onPickFile">Upload image </b-button>
                    <input type="file" style="display: none" ref="fileInput" accept="image/*" @change="onFilePicked">

                    <img :src="imageUrl" height="200">

                    <div class="add">{{ imgUrl }}</div>

                    <div class="mt-3" v-for="(md, index) in media_ids" :key="index">
                       {{ index }} {{ md }}

                    </div>

                </div>




                <div class="form-group mt-2">
                    <button class="btn btn-primary btn-block" :disabled="loading">
                        <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                        <span>Create</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>
  
<script>

import NftService from '../services/nft.service';
import AttributesModal from '../components/AttributesModal.vue';

export default {
    name: 'create',
    components: {
        AttributesModal
    },
    data() {
        return {
            loading: false,
            message: '',
            imageUrl: '',
            image: '',
            media_ids: [],
            delay: 10000,
            attributes: [],
            query: 'Drop your NFT tezos -"no text" OR tezos Shill your art -"no text" OR tezos Show me some art -"no text" OR tezos Drop your NFTs -"no text" OR Drop your tezos Art -"no text" OR Show me some tezos art -"no text" OR Share some art -"no text" OR Art share tezos -"no text" OR Art share xtz -"no text" -filter:retweets'

        };
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
        walletAddress() {
            return this.$store.getters['nft/loadedWallet'];
        },
        wallet() {
            if (this.walletAddress === '') {
                return 'Connect Wallet'
            } else {
                return String(this.walletAddress);
            }
        },
        imgUrl() {
            return this.$store.getters['nft/loadedImgUrl'];
        }

    },
    mounted() {
        if (!this.currentUser) {
            this.$router.push('/user/login');
        }
        this.loadTwitt();
    },
    methods: {
        createNode: function () {
            this.loading = true;


            if (!this.imgUrl || this.attributes.length === 0) {
                this.loading = false;
                console.log('error')
                this.$store.dispatch('nft/setMessage', 'Please fill in all fields.')
                return;
            }
            const queryData = {
                imageUrl: "https://ipfs.io/ipfs/" + this.imgUrl,
                //image: this.imgUrl,
                delay: this.delay,
                query: this.query,
                attributes: this.attributes,
            }
            this.$store.dispatch('nft/editQuery', queryData)
            this.loading = false;
            //this.$router.push('/sketchbook')



        },
        onPickFile() {
            this.$refs.fileInput.click()
        },
        onFilePicked(event) {
            const files = event.target.files
            let filename = files[0].name
            if (filename.lastIndexOf('.') <= 0) {
                return alert('Please add a valid file!')
            }
            const fileReader = new FileReader()
            fileReader.addEventListener('load', () => {
                this.imageUrl = fileReader.result
            })
            fileReader.readAsDataURL(files[0])
            this.image = files[0]
            this.$store.dispatch('nft/uploadImg', files[0])

        },
        logAttributes(value) {
            this.attributes.push(value)
        },
        rmAttribute: function (id) {
            this.attributes.splice(id, 1)
        },
        loadTwitt: function () {
            NftService.getTwitt().then(response => {
                this.query = response.query;
                this.delay = response.delay;
                this.media_ids = response.media_ids;

                response.twitt_string.forEach(element => {
                    this.attributes.push({
                        value: element
                    })
                });



            }, error => {
                this.message =
                    (error.response && error.response) ||
                    error.message ||
                    error.toString();

                this.$store.dispatch('nft/setMessage', this.message)
            });
        }

    }
};
</script>
<style scoped>
.card-container.card {
    width: 100%;
    padding: 40px 40px;
}

.card {
    background-color: #fdfdfd;
    padding: 20px 25px 30px;
    margin: 0 auto 25px;
    margin-top: 50px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.add {
    font-size: 10pt;
}
</style>