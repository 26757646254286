<template>
    <div class="container">
        <div class="top text-dark">
            <h2>HUB - {{ currentUser.username }}</h2>
            <p>{{ currentUser.address }}</p>
        </div>
        <div class="mt-4 text-dark">
            <h3>Tweet settings</h3>

            <div class="form-group">
                <label for="querying">Querying Tweets</label>
                <input v-model="query" disabled type="text" class="form-control" name="artist" />

            </div>
            <div class="form-group">
                <label for="externalUrl">Time</label>
                <div>Delay in (sec) between Tweets</div>
                <input v-model="delay" disabled type="number" class="form-control" name="externalUrl" />

            </div>
            <div class="form-group">
                <label for="attributes"><b>Tweet text</b></label>
                <div v-for="(st, index) in twitt_string" :key="index">{{ index }} {{ st }} </div>
            </div>
            <div class="form-group">
                <label for="media"><b>Media</b></label>
                <div v-for="(st, index) in media_ids" :key="index">{{ index }} {{ st }} </div>

            </div>
            <b-row>
                <b-col class="mb-4" lg="6" md="6" sm="12" xs="12">

                    <b-row>
                        <b-col cols="6">
                            <TwitterCredentialModal></TwitterCredentialModal>
                        </b-col>
                        <b-col cols="6">
                            <AutoTweet class="mt-2"></AutoTweet>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="6">
                            <SetTwitQuery v-if="!queryFilled"></SetTwitQuery>
                            <EditQueryModal v-else></EditQueryModal>
                        </b-col>
                        <b-col cols="6">
                            <DeleteModal class="mt-2" v-if="queryFilled"></DeleteModal>
                        </b-col>
                    </b-row>


                </b-col>

                <b-col lg="6" md="6" sm="12" xs="12">

                    <h3>Whitlelisted friends</h3>

                    <span v-for="(friend, index) in whitelist" :key="index">{{ friend.name }}, </span>

                    <Whitelist class="mt-4" variant="nature4" @whitelistAddress="whitelistAddress" :data="followers">
                    </Whitelist>
                    <b-button class="mt-2" variant="danger" @click="resetwhitelist" size="sm">Reset List</b-button>

                </b-col>
            </b-row>

            <b-row>
                <b-col lg="6" md="6" sm="12" xs="12">

                    <div class="mt-4 text-dark">
                        <h3>Tweet options</h3>

                        <b-row>
                            <b-col cols="6">

                                <b-button v-if="queryFilled" variant="nature1" @click="listTweets" size="sm">Retrieve
                                    Tweets</b-button>
                                <ConfirmShilling class="mt-2" :value="tweets.length"></ConfirmShilling>
                                <EmergencyModal class="mt-2"> </EmergencyModal>

                            </b-col>
                            <b-col cols="6">
                                <b-form-group label="Automate Shilling" v-slot="{ ariaDescribedby }">
                                    <b-form-radio v-model="selected" :aria-describedby="ariaDescribedby" name="some-radios"
                                        :value="true">
                                        ON</b-form-radio>
                                    <b-form-radio v-model="selected" :aria-describedby="ariaDescribedby" name="some-radios"
                                        :value="false">
                                        OFF</b-form-radio>
                                </b-form-group>
                                <b-button class="mt-2" variant="nature3" @click="shill">GO</b-button>
                            </b-col>
                        </b-row>
                        <b-row class="mt-3">
                            <b-col lg="6" md="6" sm="6" xs="12">
                                <b-form-group label="Account Overwatch" v-slot="{ ariaDescribedby2 }">
                                    <b-form-radio v-model="selected2" :aria-describedby2="ariaDescribedby2"
                                        name="some-radios2" :value="true">
                                        ON</b-form-radio>
                                    <b-form-radio v-model="selected2" :aria-describedby2="ariaDescribedby2"
                                        name="some-radios2" :value="false">
                                        OFF</b-form-radio>
                                </b-form-group>
                                <b-button class="mt-2" variant="nature3" @click="detectMention">GO</b-button>
                            </b-col>
                            <b-col lg="6" md="6" sm="6" xs="12">

                                <b-form-group label="AI Tweet Processing" v-slot="{ ariaDescribedby3 }">
                                    <b-form-radio v-model="selected3" :aria-describedby3="ariaDescribedby3"
                                        name="some-radios3" :value="true">
                                        ON</b-form-radio>
                                    <b-form-radio v-model="selected3" :aria-describedby3="ariaDescribedby3"
                                        name="some-radios3" :value="false">
                                        OFF</b-form-radio>
                                </b-form-group>
                                <div>query: {{ query.substring(0, 16) }}</div>
                                <b-button class="mt-2" variant="nature3" @click="retweetFavorite">GO</b-button>

                            </b-col>
                        </b-row>
                        <b-row class="mt-3">
                            <b-col lg="6" md="6" sm="6" xs="12">
                                <b-form-group label="AI Automation" v-slot="{ ariaDescribedby4 }">
                                    <b-form-radio v-model="selected4" :aria-describedby4="ariaDescribedby4"
                                        name="some-radios4" :value="true">
                                        ON</b-form-radio>
                                    <b-form-radio v-model="selected4" :aria-describedby4="ariaDescribedby4"
                                        name="some-radios4" :value="false">
                                        OFF</b-form-radio>
                                </b-form-group>
                                <b-button class="mt-2" variant="nature3" @click="getPrivateMessage">GO</b-button>
                            </b-col>

                            <b-col lg="6" md="6" sm="6" xs="12">
                                <b-form-group label="Shill Tweet Analysis" v-slot="{ ariaDescribedby5 }">
                                    <b-form-radio v-model="selected5" :aria-describedby5="ariaDescribedby5"
                                        name="some-radios5" :value="true">
                                        ON</b-form-radio>
                                    <b-form-radio v-model="selected5" :aria-describedby5="ariaDescribedby5"
                                        name="some-radios5" :value="false">
                                        OFF</b-form-radio>
                                </b-form-group>
                                <b-button class="mt-2" variant="nature3" @click="aiPredict">GO</b-button>
                            </b-col>

                        </b-row>

                        <b-row class="mt-3">
                            <b-col lg="6" md="6" sm="6" xs="12">
                                <b-form-group label="Transfer to Friends" v-slot="{ ariaDescribedby6 }">
                                    <b-form-radio v-model="selected6" :aria-describedby6="ariaDescribedby6"
                                        name="some-radios6" :value="true">
                                        ON</b-form-radio>
                                    <b-form-radio v-model="selected6" :aria-describedby6="ariaDescribedby6"
                                        name="some-radios6" :value="false">
                                        OFF</b-form-radio>
                                </b-form-group>
                                <b-button class="mt-2" variant="nature3" @click="transferWhitelist">GO</b-button>
                            </b-col>



                        </b-row>
                        <h3 class="mt-4">Retrieved Tweets ({{ tweets.length }})</h3>
                        <b-button v-b-toggle.collapse variant="light" size="sm">
                            <b-icon v-if="retrieveVisible" icon="chevron-up"></b-icon>
                            <b-icon v-else icon="chevron-down"></b-icon>
                        </b-button>
                        <b-collapse id="collapse" class="mt-2" v-model="retrieveVisible">
                            <b-button class="m-2" variant="danger" @click="deleteTweets" size="sm">Delete Tweets</b-button>
                            <b-card v-for="tweet in tweets" :key="tweet.id" class="mb-2 mt-3">
                                <b-card-text>
                                    <p>{{ tweet.id }}</p>
                                    <p>{{ tweet.text }}</p>
                                    <p>{{ tweet.user.name }}</p>
                                    <p>{{ tweet.created_at }}</p>
                                    <p>Has shilled: {{ tweet.shill }}</p>
                                </b-card-text>
                            </b-card>
                        </b-collapse>
                    </div>
                </b-col>

                <b-col lg="6" md="6" sm="12" xs="12">
                    
                    <div class="mt-4 text-dark">
                        <h3>Auto Tweets ({{ autoTweet.length }})</h3>
                        <b-button v-b-toggle.collapse-7 variant="light" size="sm">
                            <b-icon v-if="autoVisible" icon="chevron-up"></b-icon>
                            <b-icon v-else icon="chevron-down"></b-icon>
                        </b-button>
                        <b-collapse id="collapse-7" class="mt-2" v-model="autoVisible">

                            <b-row>
                                <b-col lg="4" md="4" sm="4" xs="6" v-for="tweet in autoTweet" :key="tweet._id">
                                    <b-card class="mb-2 mt-3 twt">
                                        <b-row>
                                            <b-col>


                                                <b-card-text>


                                                    <div class="add" v-for="(attribute, index) in tweet.twitt_string"
                                                        :key="index">
                                                        <p>{{ attribute }}</p>
                                                    </div>


                                                    <div class="add" v-for="tm in tweet.time" :key="tm._id">
                                                        {{ tm.date }} {{ tm.time }}


                                                    </div>
                                                    <div class="mt-3" v-for="(md, index3) in tweet.media_ids" :key="index3">
                                                        {{ index3 }} {{ md }}

                                                    </div>
                                                    <div class="mt-3">
                                                        Active: {{ tweet.active }}

                                                    </div>
                                                </b-card-text>
                                            </b-col>
                                            <b-col cols="ml-auto">
                                                <b-button class="mt-2" variant="danger" @click="deleteAutoTweet(tweet._id)"
                                                    size="sm"><b-icon icon="trash"></b-icon></b-button>
                                                <EditAutoTweet :id="tweet._id" />
                                                <b-button class="mt-2" variant="nature4" size="sm"
                                                    @click="toggleEvent(tweet._id, !tweet.active)">
                                                    <b-icon v-if="tweet.active" icon="toggle-on"></b-icon>
                                                    <b-icon v-else icon="toggle-off"></b-icon>
                                                </b-button>
                                            </b-col>
                                        </b-row>
                                    </b-card>

                                </b-col>
                            </b-row>

                        </b-collapse>
                    </div>
                    <div class="mt-4 text-dark">
                        <h3>My tweets ({{ userTweets.length }})</h3>
                        <b-button v-b-toggle.collapse-2 variant="light" size="sm">
                            <b-icon v-if="catVisible" icon="chevron-up"></b-icon>
                            <b-icon v-else icon="chevron-down"></b-icon>
                        </b-button>
                        <b-collapse id="collapse-2" class="mt-2" v-model="catVisible">
                            <b-card v-for="tweet in userTweets" :key="tweet.id" class="mb-2 mt-3">
                                <b-card-text>
                                    <p>{{ tweet.id_str }}</p>
                                    <p>{{ tweet.text }}</p>
                                    <p>{{ tweet.user.name }} - {{ tweet.user.id_str }}</p>
                                    <p>{{ tweet.created_at }}</p>
                                </b-card-text>
                            </b-card>
                        </b-collapse>

                    </div>
                </b-col>
            </b-row>
            <b-row>
                <b-col lg="6" md="6" sm="12" xs="12">
                    <div class="mt-4 text-dark">
                        <h3>AI Processing ({{ aiTweets.length }})</h3>
                        <b-button class="mt-2" v-b-toggle.collapse-3 variant="light" size="sm">
                            <b-icon v-if="aiVisible" icon="chevron-up"></b-icon>
                            <b-icon v-else icon="chevron-down"></b-icon>
                        </b-button>
                        <b-collapse id="collapse-3" class="mt-2" v-model="aiVisible">
                            <b-button class="mt-2" variant="danger" @click="clearAITweets" size="sm">Clear AI Tweet
                                list</b-button>
                            <b-card v-for="tweet in aiTweets" :key="tweet.id" class="mb-2 mt-3">
                                <b-card-text>
                                    <p>{{ tweet.id }}</p>
                                    <p>{{ tweet.text }}</p>
                                    <p>{{ tweet.user.name }}</p>
                                    <p>{{ tweet.created_at }}</p>
                                    <p>{{ tweet.sentiment }}</p>
                                    <p>Has shilled: {{ tweet.shill }}</p>
                                </b-card-text>
                            </b-card>
                        </b-collapse>
                    </div>
                </b-col>
                <b-col lg="6" md="6" sm="12" xs="12">
                    <div class="mt-4 text-dark">
                        <h3>Analyzed Shill tweets ({{ shillTweets.length }})</h3>
                        <b-button class="mt-2" v-b-toggle.collapse-4 variant="light" size="sm">
                            <b-icon v-if="shillVisible" icon="chevron-up"></b-icon>
                            <b-icon v-else icon="chevron-down"></b-icon>
                        </b-button>
                        <b-collapse id="collapse-4" class="mt-2" v-model="shillVisible">
                            <b-button class="mt-2" variant="danger" @click="clearShillTweets" size="sm">Clear Tweet
                                list</b-button>
                            <b-card v-for="tweet in shillTweets" :key="tweet.id" class="mb-2 mt-3">
                                <b-card-text>
                                    <p>ID: {{ tweet.id }}</p>
                                    <p>{{ tweet.text }}</p>
                                    <p>{{ tweet.username }}</p>
                                    <p class="mt-2">User:</p>
                                    <p>bio: {{ tweet.user_description }}</p>
                                    <p>ID: {{ tweet.user_id }}</p>
                                    <p>Username: {{ tweet.user_name }}</p>
                                    <p>Tansfered: {{ tweet.transfered }}</p>
                                </b-card-text>
                            </b-card>
                        </b-collapse>
                    </div>
                </b-col>
            </b-row>
        </div>

    </div>
</template>
  
<script>

import NftService from '../services/nft.service';
import DeleteModal from '../components/DeleteModal.vue';
import Whitelist from '../components/Whitelist.vue';
import EmergencyModal from '../components/EmergencyModal.vue';
import ConfirmShilling from '../components/ConfirmShilling.vue';
import SetTwitQuery from '../components/SetTwitQuery.vue';
import EditQueryModal from '../components/EditQueryModal.vue';
import TwitterCredentialModal from '../components/TwitterCredentialModal.vue';
import AutoTweet from '../components/AutoTweet.vue';
import EditAutoTweet from '../components/EditAutoTweet.vue';

import EventBus from '../common/EventBus';

export default {
    name: 'Profile',
    data() {
        return {
            query: '',
            delay: 0,
            twitt_string: [],
            media_ids: [],
            selected: false,
            selected2: false,
            selected3: false,
            selected4: false,
            selected5: false,
            selected6: false,
            followers: [],
            whitelist: [],
            catVisible: false,
            retrieveVisible: false,
            aiVisible: false,
            shillVisible: false,
            autoTweet: [],
            autoVisible: false,
        };
    },
    components: {
        DeleteModal,
        Whitelist,
        EmergencyModal,
        ConfirmShilling,
        SetTwitQuery,
        EditQueryModal,
        TwitterCredentialModal,
        AutoTweet,
        EditAutoTweet,
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
        queryFilled() {
            return this.media_ids.length > 0;
        },
        tweets() {
            return this.$store.getters['nft/getTweets'];
        },
        userTweets() {
            return this.$store.getters['nft/getUserTweets'];
        },
        aiTweets() {
            return this.$store.getters['nft/getAITweets'];
        },
        shillTweets() {
            return this.$store.getters['nft/getShillTweets'];
        },
    },
    mounted() {
        if (!this.currentUser) {
            this.$router.push('/login');
        }
        this.loadTwitt();
        this.loadFollowers();
        this.loadWhitelist();
    },
    created() {
        this.interval = setInterval(async () => {
            await this.$store.dispatch('nft/listTweets')
            await this.$store.dispatch('nft/getAllTweets')
            await this.$store.dispatch('nft/getUserTweets')
            await this.$store.dispatch('nft/getAITweets')
            await this.$store.dispatch('nft/getShillTweets')

        }, 60000)
        this.$store.dispatch('nft/listTweets')
        this.$store.dispatch('nft/getAllTweets')
        this.$store.dispatch('nft/getUserTweets')
        this.$store.dispatch('nft/getAITweets')
        this.$store.dispatch('nft/getShillTweets')
    },
    beforeDestroy() {
        clearInterval(this.interval)
    },
    methods: {
        loadTwitt: function () {
            NftService.getTwitt().then(response => {
                this.query = response.query;
                this.delay = response.delay;
                this.twitt_string = response.twitt_string;
                this.media_ids = response.media_ids;
                this.selected = response.shill;
                this.selected2 = response.overwatch;
                this.selected3 = response.ai_processing;
                this.selected4 = response.auto_reply;
                this.selected5 = response.nlp;
                this.selected6 = response.transfer;
            }, error => {
                this.message =
                    (error.response && error.response) ||
                    error.message ||
                    error.toString();

                if (error.response && error.response.status === 403) {
                    EventBus.dispatch('logout')
                }

                this.$store.dispatch('nft/setMessage', this.message)
            });
        },
        loadFollowers: function () {
            NftService.getFollowers().then(response => {
                this.followers = response;
            }, error => {
                this.message =
                    (error.response && error.response) ||
                    error.message ||
                    error.toString();

                if (error.response && error.response.status === 403) {
                    EventBus.dispatch('logout')
                }

                this.$store.dispatch('nft/setMessage', this.message)
            });
        },
        loadWhitelist: function () {
            NftService.getWhitelistFriends().then(response => {
                this.whitelist = response;
            }, error => {
                this.message =
                    (error.response && error.response) ||
                    error.message ||
                    error.toString();

                if (error.response && error.response.status === 403) {
                    EventBus.dispatch('logout')
                }

                this.$store.dispatch('nft/setMessage', this.message)
            });
        },
        loadAutoTweet: function () {
            NftService.getAutoTweet().then(response => {
                this.autoTweet = response || [];

            }, error => {
                this.message =
                    (error.response && error.response) ||
                    error.message ||
                    error.toString();

                if (error.response && error.response.status === 403) {
                    EventBus.dispatch("logout");
                }

                this.$store.dispatch('nft/setMessage', this.message)
            });
        },
        listTweets: function () {
            this.$store.dispatch('nft/listTweets')
        },
        deleteTweets: function () {
            this.$store.dispatch('nft/deleteAllTweets')
        },
        manualShill: function () {
            this.$store.dispatch('nft/manualShill')
        },
        shill: function () {
            const obj = {
                state: this.selected
            }
            this.$store.dispatch('nft/shill', obj)
        },
        detectMention: function () {
            const obj = {
                state: this.selected2
            }
            this.$store.dispatch('nft/detectMention', obj)
        },
        retweetFavorite: function () {

            const obj = {
                state: this.selected3
            }
            this.$store.dispatch('nft/retweetFavorite', obj)
        },
        getPrivateMessage: function () {
            const obj = {
                state: this.selected4
            }
            this.$store.dispatch('nft/getTwitterPrivateMessages', obj)
        },
        aiPredict: function () {
            const obj = {
                state: this.selected5
            }
            this.$store.dispatch('nft/aiPredict', obj)
        },
        whitelistAddress: function (data) {
            console.log(data)
            this.$store.dispatch('nft/whitelistFriend', data)
        },
        transferWhitelist: function () {
            const obj = {
                state: this.selected6
            }
            this.$store.dispatch('nft/transferTweet', obj)
        },
        resetwhitelist: function () {
            this.$store.dispatch('nft/resetWhitelist')
        },
        clearShillTweets: function () {
            this.$store.dispatch('nft/clearShillTweets')
        },
        clearAITweets: function () {
            this.$store.dispatch('nft/clearAITweets')
        }
    }
};
</script>
<style scoped>
.info {
    margin-top: 40px;
}

.top {
    margin-top: 40px;
}
</style>