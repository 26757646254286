<template>
    <div class="container">
        <header class="jumbotron">

            <b-jumbotron text-variant="dark" header="Access API">
                <p>Twitter Developer Credentials</p>
            </b-jumbotron>

        </header>

        <div class="card card-container text-dark">

            <div class="form-group">
                <label for="username">User ID</label>
                <input v-model="currentUser.id" disabled type="text" class="form-control" name="artist" />
            </div>

            <div class="form-group">
                <label for="username">Username</label>
                <input v-model="currentUser.username" disabled type="text" class="form-control" name="artist" />
            </div>

            <div class="form-group">
                <label for="username">Address</label>
                <input v-model="currentUser.address" disabled type="text" class="form-control" name="artist" />
            </div>

            <form name="form" @submit.prevent="createNode">
                <div class="form-group">
                    <label for="at">Access Token</label>
                    <input v-model="access_token" v-validate="'required'" type="text" class="form-control" name="name" />
                </div>

                <div class="form-group">
                    <label for="ats">Access Token Secret</label>
                    <b-form-textarea id="description" v-model="access_token_secret" placeholder="..." rows="3"
                        max-rows="6"></b-form-textarea>
                </div>

                <div class="form-group">
                    <label for="apik">API Key</label>
                    <input v-model="api_key" type="text" class="form-control" name="artist" />
                </div>

                <div class="form-group">
                    <label for="apis">API Secret</label>
                    <input v-model="api_secret" type="text" class="form-control" name="website" />
                </div>


                <div class="form-group mt-2">
                    <button class="btn btn-primary btn-block" :disabled="loading">
                        <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                        <span>Create</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>
  
<script>

import AuthService from '../services/auth.service';

export default {
    name: 'create',
    components: {

    },
    data() {
        return {
            loading: false,
            message: '',
            access_token: '',
            access_token_secret: '',
            api_key: '',
            api_secret: ''
        };
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
        walletAddress() {
            return this.$store.getters['nft/loadedWallet'];
        },
        wallet() {
            if (this.walletAddress === '') {
                return 'Connect Wallet'
            } else {
                return String(this.walletAddress);
            }
        }

    },
    mounted() {
        if (!this.currentUser) {
            this.$router.push('/user/login');
        }
        this.loadCredentials();
    },
    methods: {
        createNode: function () {
            this.loading = true;


            if (!this.access_token || !this.access_token_secret ||
                !this.api_key || !this.api_secret) {
                this.loading = false;
                console.log('error')
                this.$store.dispatch('nft/setMessage', 'Please fill in all fields.')
                return;
            }
            const credentialsData = {
                access_token: this.access_token,
                access_token_secret: this.access_token_secret,
                api_key: this.api_key,
                api_secret: this.api_secret,
            }
            this.$store.dispatch('auth/saveCredentials', credentialsData)
            this.loading = false;
            //this.$router.push('/sketchbook')



        },
        loadCredentials: function () {
            AuthService.getCredentials().then(response => {
                this.access_token = response.access_token;
                this.access_token_secret = response.access_token_secret;
                this.api_key = response.consumer_key;
                this.api_secret = response.consumer_secret;
            }, error => {
                this.message =
                    (error.response && error.response) ||
                    error.message ||
                    error.toString();

                this.$store.dispatch('nft/setMessage',  this.message)
            });
        }

    }
};
</script>
<style scoped>
.card-container.card {
    width: 100%;
    padding: 40px 40px;
}

.card {
    background-color: #fdfdfd;
    padding: 20px 25px 30px;
    margin: 0 auto 25px;
    margin-top: 50px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.add {
    font-size: 10pt;
}
</style>