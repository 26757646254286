<template>
    <div>
        <b-button variant="primary" class="mt-2" v-b-modal.modal8-prevent-closing size="sm"><b-icon icon="twitter" />Edit
            Query</b-button>

        <b-modal size="xl" id="modal8-prevent-closing" ref="modal8" title="Query tweets" @show="resetModal8"
            @hidden="resetModal8" @ok="handleOk8">

            <b-row>
                <b-col lg="6" md="6" sm="6" xs="6">

                



                    <div class="form-group">
                        <label for="querying">Querying Tweets</label>
                        <input v-model="query" type="text" class="form-control" name="artist" />
                    </div>

                    <div class="form-group mt-4">
                        <label for="attributes"><b>Query</b></label>
                        <div>A list keywords</div>
                        <AttributesModal @logAttributes="logAttributes"></AttributesModal>
                    </div>

                    <div v-if="attributes.length > 0">
                        <b-row>
                            <b-col cols="6" v-for="(attribute, index) in attributes" :key="index">
                                <b-card bg-variant="light">
                                    <b-button size="sm" variant="light" @click="rmAttribute(index)">
                                        <b-icon icon="trash"></b-icon>
                                    </b-button>
                                    <div class="text-center add">
                                        <p>{{ attribute.value }}</p>
                                    </div>
                                </b-card>
                            </b-col>

                        </b-row>
                    </div>

                    <div class="form-group">
                        <label for="externalUrl">Time</label>
                        <div>Delay in (millisec) between Tweets</div>
                        <input v-model="delay" type="number" class="form-control" name="externalUrl" />
                    </div>

                </b-col>
                <b-col lg="6" md="6" sm="6" xs="6">

                    <div class="form-group mt-2">
                        <div><b>Image content</b></div>
                        <div>The main asset of your Project, can be <b>JPEG, PNG or GIF</b></div>
                        <b-button variant="primary" @click="onPickFile">Upload image </b-button>
                        <input type="file" style="display: none" ref="fileInput" accept="image/*" @change="onFilePicked">

                        <img :src="imageUrl" height="200">

                        <div class="add">{{ imgUrl }}</div>

                        <div class="mt-3" v-for="(md, index) in media_ids" :key="index">
                            {{ index }} {{ md }}

                        </div>

                    </div>
                </b-col>
            </b-row>

        </b-modal>
    </div>
</template>
  
<script>

import AttributesModal from './AttributesModal.vue';
import NftService from '../services/nft.service';

export default {
    data() {
        return {
            loading: false,
            message: '',
            imageUrl: '',
            image: '',
            media_ids: [],
            delay: 10000,
            attributes: [],
            query: 'Drop your NFT tezos -"no text" OR tezos Shill your art -"no text" OR tezos Show me some art -"no text" OR tezos Drop your NFTs -"no text" OR Drop your tezos Art -"no text" OR Show me some tezos art -"no text" OR Share some art -"no text" OR Art share tezos -"no text" OR Art share xtz -"no text" -filter:retweets'
        }
    },
    components: {
        AttributesModal
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
        imgUrl() {
            return this.$store.getters['twitter/loadedImgUrl'];
        }

    },
    mounted() {
        this.loadTwitt();
    },
    methods: {


        resetModal8() {

        },
        handleOk8(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.handleSubmit8()
        },
        handleSubmit8() {
            // Exit when the form isn't valid
            this.loading = true;


            if (!this.imgUrl || this.attributes.length === 0) {
                this.loading = false;
                console.log('error')
                this.$store.dispatch('nft/setMessage', 'Please fill in all fields.')
                return;
            }
            const queryData = {
                imageUrl: "https://ipfs.io/ipfs/" + this.imgUrl,
                //image: this.imgUrl,
                delay: this.delay,
                query: this.query,
                attributes: this.attributes,
            }
            this.$store.dispatch('twitter/editQuery', queryData)
            this.loading = false;

            this.$nextTick(() => {
                this.$bvModal.hide('modal8-prevent-closing')
            })
        },
        onPickFile() {
            this.$refs.fileInput.click()
        },
        onFilePicked(event) {
            const files = event.target.files
            let filename = files[0].name
            if (filename.lastIndexOf('.') <= 0) {
                return alert('Please add a valid file!')
            }
            const fileReader = new FileReader()
            fileReader.addEventListener('load', () => {
                this.imageUrl = fileReader.result
            })
            fileReader.readAsDataURL(files[0])
            this.image = files[0]
            this.$store.dispatch('twitter/uploadImg', files[0])

        },
        logAttributes(value) {
            this.attributes.push(value)
        },
        rmAttribute: function (id) {
            this.attributes.splice(id, 1)
        },
        loadTwitt: function () {
            NftService.getTwitt().then(response => {
                this.query = response.query || this.query;
                this.delay = response.delay || this.delay;
                this.media_ids = response.media_ids || this.media_ids;

                if (response.twitt_string) {
                    response.twitt_string.forEach(element => {
                        this.attributes.push({
                            value: element
                        })
                    });
                } else {
                    this.attributes = [];
                }



            }, error => {
                this.message =
                    (error.response && error.response) ||
                    error.message ||
                    error.toString();

                this.$store.dispatch('nft/setMessage', this.message)
            });
        }
    }
}
</script>
<style scoped>
.add {
    font-size: 10pt;
}

.form-group {
    font-size: 10pt;
}
</style>