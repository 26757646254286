import NftService from '../services/nft.service';
require('dotenv').config();


export const nft = {
  namespaced: true,
  state: {
    walletAddress: '',
    imageUrl: '',
    message: '',
    attributes: [],
    tweets: [],
    userTweets: [],
    aiTweets: [],
    shillTweets: [],
  },
  actions: {
    uploadImg({ commit }, payload) {
      return NftService.upload(payload).then(
        data => {
          commit('uploadImg', data);
          commit('setMessage', "Upload Success")
          return Promise.resolve(data);
        },
        error => {
          commit('setMessage', "Upload Failed")
          return Promise.reject(error);
        }
      );
    },


    async setQuery ({ commit }, payload) {
      NftService.setQuery(payload).then(
        data => {
          commit('setMessage', "Query Success")
          return Promise.resolve(data);
        },
        error => {
          commit('setMessage', "Query Failed")
          return Promise.reject(error);
        }
      );
    },

    async editQuery ({ commit }, payload) {
      NftService.editQuery(payload).then(
        data => {
          commit('setMessage', "Query Edit Success")
          return Promise.resolve(data);
        },
        error => {
          commit('setMessage', "Query Edit Failed")
          return Promise.reject(error);
        }
      );
    },

    async deleteQuery ({ commit }, payload) {
      NftService.deleteQuery(payload).then(
        data => {
          commit('setMessage', "Query Delete Success")
          return Promise.resolve(data);
        },
        error => {
          commit('setMessage', "Query Delete Failed")
          return Promise.reject(error);
        }
      );
    },

    async listTweets ({ commit, dispatch }) {
      NftService.listTweets().then(
        data => {
          commit('setMessage', "Query Success");
          dispatch('getAllTweets');
          return Promise.resolve(data);
        },
        error => {
          commit('setMessage', "Query Failed")
          return Promise.reject(error);
        }
      );
    },

    async getUserTweets ({ commit }) {
      NftService.getUserTweets().then(
        data => {
          commit('getUserTweets', data);
          commit('setMessage', "Query Success")
          return Promise.resolve(data);
        },
        error => {
          commit('setMessage', "Query Failed")
          return Promise.reject(error);
        }
      );
    },

    async getAllTweets ({ commit }) {
      NftService.getAllTweets().then(
        data => {
          commit('getAllTweets', data);
          commit('setMessage', "Query Success")
          return Promise.resolve(data);
        },
        error => {
          commit('setMessage', "Query Failed")
          return Promise.reject(error);
        }
      );
    },

    async transferTweet ({ commit }, payload) {
      NftService.transferTweet(payload).then(
        data => {
          commit('setMessage', data.message)
          return Promise.resolve(data);
        },
        error => {
          commit('setMessage', "Query Failed")
          return Promise.reject(error);
        }
      );
    },

    async resetWhitelist ({ commit }) {
      NftService.resetwhitelist().then(
        data => {
          commit('setMessage', data.message)
          return Promise.resolve(data);
        },
        error => {
          commit('setMessage', "Query Failed")
          return Promise.reject(error);
        }
      );
    },

    async clearShillTweets ({ commit }) {
      NftService.clearShillTweets().then(
        data => {
          commit('clearShillTweets')
          commit('setMessage', data.message)
          return Promise.resolve(data);
        },
        error => {
          commit('setMessage', "Query Failed")
          return Promise.reject(error);
        }
      );
    },

    async clearAITweets ({ commit }) {
      NftService.clearAITweets().then(
        data => {
          commit('clearAITweets')
          commit('setMessage', data.message)
          return Promise.resolve(data);
        },
        error => {
          commit('setMessage', "Query Failed")
          return Promise.reject(error);
        }
      );
    },

    async emergencyStop ({ commit }) {
      NftService.emergencyStop().then(
        data => {
          commit('setMessage', data.message)
          return Promise.resolve(data);
        },
        error => {
          commit('setMessage', "Query Failed, contact support!")
          return Promise.reject(error);
        }
      );
    },

    async deleteAllTweets ({ commit }) {
      NftService.deleteAllTweets().then(
        data => {
          commit('deleteAllTweets');
          commit('setMessage', data.message)
          return Promise.resolve(data);
        },
        error => {
          commit('setMessage', "Query Failed")
          return Promise.reject(error);
        }
      );
    },

    async getTwitterPrivateMessages ({ commit }, payload) {
      NftService.getTwitterPrivateMessages(payload).then(
        data => {
          // commit('getTwitterPrivateMessages', data);
          commit('setMessage', data.message)
          return Promise.resolve(data);
        },
        error => {
          commit('setMessage', "Query Failed")
          return Promise.reject(error);
        }
      );
    },

    async aiPredict ({ commit }, payload) {
      NftService.aiPredict(payload).then(
        data => {
          commit('setMessage', data.message)
          return Promise.resolve(data);
        },
        error => {
          commit('setMessage', "Query Failed")
          return Promise.reject(error);
        }
      );
    },

    async getShillTweets ({ commit }, payload) {
      NftService.getShillTweets(payload).then(
        data => {
          commit('aiPredict', data);
          commit('setMessage', 'Query Success')
          return Promise.resolve(data);
        },
        error => {
          commit('setMessage', "Query Failed")
          return Promise.reject(error);
        }
      );
    },

    whitelistFriend({ commit }, payload) {
      NftService.whitelistFriend(payload).then(
        data => {
          commit('setMessage', data.message)
          return Promise.resolve(data);
        },
        error => {
          commit('setMessage', "Query Failed")
          return Promise.reject(error);
        }
      );
    },

    async manualShill ({ commit }) {
      NftService.manualShill().then(
        data => {
          commit('setMessage', "Query Success")
          return Promise.resolve(data);
        },
        error => {
          commit('setMessage', "Query Failed")
          return Promise.reject(error);
        }
      );
    },

    async shill({ commit }, payload) {
      NftService.shill(payload).then(
        data => {
          commit('setMessage', data.message)
          return Promise.resolve(data);
        },
        error => {
          commit('setMessage', "Query Failed")
          return Promise.reject(error);
        }
      );
    },

   async detectMention({ commit }, payload) {
      NftService.detectMention(payload).then(
        data => {
          commit('setMessage', data.message)
          return Promise.resolve(data);
        },
        error => {
          commit('setMessage', "Query Failed")
          return Promise.reject(error);
        }
      );
    },
    async retweetFavorite({ commit }, payload) {
      NftService.retweetFavorite(payload).then(
        data => {
          commit('setMessage', data.message)
          return Promise.resolve(data);
        },
        error => {
          commit('setMessage', "Query Failed")
          return Promise.reject(error);
        }
      );
    },
    async getAITweets({ commit }, payload) {
      NftService.getAITweets(payload).then(
        data => {
          commit('getAITweets', data);
          commit('setMessage', 'Query Success')
          return Promise.resolve(data);
        },
        error => {
          commit('setMessage', "Query Failed")
          return Promise.reject(error);
        }
      );
    },
    async connectWallet({ commit }) {
      if (window.ethereum) {
        try {
          const addressArray = await window.ethereum.request({
            method: "eth_requestAccounts",
          });
          commit('connectWallet', addressArray[0]);
          commit('setMessage', `Connected to ${addressArray[0]}`)
        } catch (err) {
          console.log(err.message);
          commit('setMessage', err.message)
        }
      } else {
        commit('setMessage', "You must install Metamask 🦊, a virtual Ethereum wallet, in your browser.");
        console.log("You must install Metamask");
      }
    },
    async getCurrentWallet({ commit }) {
      if (window.ethereum) {
        try {
          const addressArray = await window.ethereum.request({
            method: "eth_accounts",
          });
          if (addressArray.length > 0) {
            commit('connectWallet', addressArray[0]);
          }
        } catch (err) {
          console.log(err.message);
        }
      }
    },


    setMessage({ commit }, payload) {
      commit('setMessage', payload);
    }
  },
  mutations: {
    uploadImg(state, payload) {
      state.imageUrl = payload.ipfsHash;
    },
    getAllTweets(state, payload) {
      state.tweets = payload;
    },
    getUserTweets(state, payload) {
      state.userTweets = payload;
    },
    getAITweets(state, payload) {
      state.aiTweets = payload;
    },
    aiPredict(state, payload) {
      state.shillTweets = payload;
    },
    connectWallet(state, payload) {
      state.walletAddress = payload;
    },
    setMessage(state, payload) {
      state.message = payload;
    },
    deleteAllTweets(state) {
      state.tweets = [];
    },
    clearShillTweets(state) {
      state.shillTweets = [];
    },
    clearAITweets(state) {
      state.aiTweets = [];
    }

  },
  getters: {
    loadedImgUrl(state) {
      //return state.loadedMatter.reverse()
      return state.imageUrl;
    },
    getTweets(state) {
      return state.tweets.reverse();
    },
    getAITweets(state) {
      return state.aiTweets;
    },
    getUserTweets(state) {
      return state.userTweets;
    },
    getShillTweets(state) {
      return state.shillTweets;
    },
    loadedWallet(state) {
      return state.walletAddress;
    },
    getMessage(state) {
      return state.message;
    }
  }
};