<template>
    <div>
      <div>
       <b-button @click="confirmEmergency" size="sm" variant="nature6">
       <b-icon icon="exclamation-circle-fill"></b-icon> Emergency
       </b-button>
      </div>
    </div>
  </template>
  
  <script>
    export default {
      data() {
        return {
          
        }
      },
      computed: {
      
      },
      methods: {
        confirmEmergency() {
        
          this.$bvModal.msgBoxConfirm('Emergency Stop?')
            .then(value => {
              if (value) {
                this.$store.dispatch('nft/emergencyStop')
      
              }
            })
            .catch(err => {
              console.log(err);
            })
        },
      }
    }
  </script>