<template>
  <div>
    <b-button v-b-modal.modal-prevent-closing>Create Tweet Template</b-button>

    <b-modal
      id="modal-prevent-closing"
      ref="modal"
      title="Submit A tweet"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">

        <b-form-group
          label="Tweet"
          label-for="query-input"
  
        >
          <b-form-input
          id="query-input"
          v-model="value"
          required
        ></b-form-input>
        </b-form-group>

      </form>
    </b-modal>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        value: ''
      }
    },
    computed: {    
    },
    methods: {
      
      resetModal() {
        this.value = ''
      },
      handleOk(bvModalEvt) {
        // Prevent modal from closing
        bvModalEvt.preventDefault()
        // Trigger submit handler
        this.handleSubmit()
      },
      handleSubmit() {
        // Exit when the form isn't valid
        if (this.value === '') {
          return
        }
        
        const attributesData = {
          value: this.value
        }
        this.$emit('logAttributes', attributesData)

        this.$nextTick(() => {
          this.$bvModal.hide('modal-prevent-closing')
        })
      }
    }
  }
</script>