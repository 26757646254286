<template>
    <div>
        <b-button size="sm" v-b-modal.modal10-prevent-closing><b-icon
                icon="stopwatch" />Pick Time</b-button>

        <b-modal id="modal10-prevent-closing" ref="modal10" title="Submit A tweet" @show="resetModal10"
            @hidden="resetModal10" @ok="handleOk10">
            <form ref="form" @submit.stop.prevent="handleSubmit10">

                <div class="form-group mt-2">
                    <label for="example-datepicker">Choose a date</label>
                    <b-form-datepicker id="example-datepicker" v-model="date" class="mb-2"></b-form-datepicker>
                    <p>Date: '{{ date }}'</p>
                </div>

                <div class="form-group mt-2">
                    <label for="example-timepicker">Choose a time</label>
                    <b-form-timepicker v-model="time" locale="en"></b-form-timepicker>
                    <div class="mt-2">Time: '{{ time }}'</div>
                </div>

            </form>
        </b-modal>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            date: '',
            time: '',
        }
    },
    computed: {
    },
    methods: {

        resetModal10() {
            this.value = ''
        },
        handleOk10(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.handleSubmit10()
        },
        handleSubmit10() {
            // Exit when the form isn't valid
            if (this.date === '' || this.time === '') {
                return
            }

            const timeData = {
                date: this.date,
                time: this.time
            }
            this.$emit('logTime', timeData)

            this.$nextTick(() => {
                this.$bvModal.hide('modal10-prevent-closing')
            })
        }
    }
}
</script>