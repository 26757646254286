<template>
    <div>
      <b-button variant="nature4" size="sm" v-b-modal.modal-prevent-closing2>
        Whitelist
      </b-button>
  
      <b-modal id="modal-prevent-closing2" ref="modal" title="Whitelist Address" @show="resetModal"
        @hidden="resetModal" @ok="handleOk">
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-form-group label="User Profile Name" label-for="value-input">
            <b-form-select v-model="selectedUser" :options="options"></b-form-select>
            <b-form-input id="value-input" v-model="idInput" :placeholder="selectedUser"></b-form-input>
          </b-form-group>
  
        </form>
      </b-modal>
    </div>
  </template>
  
  <script>
  
  import { mapState } from 'vuex';
  
  export default {
    props: ['data'],
    name: 'SetValue',
    data() {
      return {
        selectedUser: null,
        idInput: '',
      }
    },
    computed: {
      ...mapState('auth.module', {
        currentUser: (state) => state.user
      }),
      options () {
        const friends = this.data.map((user) => {
          return {
            value: user.id,
            text: user.username
          }
        })
        return friends.sort((a, b) => a.text.localeCompare(b.text))
      }
   
    },
    methods: {
  
      resetModal() {
      
         
      },
      handleOk(bvModalEvt) {
        // Prevent modal from closing
        bvModalEvt.preventDefault()
        // Trigger submit handler
        this.handleSubmit()
      },
      handleSubmit() {
        // Exit when the form isn't valid
        if (this.selectedUser === null && this.idInput === '') {
          return
        }
        const obj = {
          whitelist_user_id: this.selectedUser || this.idInput,
        }
  
        this.$emit('whitelistAddress', obj)
  
        this.$nextTick(() => {
          this.$bvModal.hide('modal-prevent-closing2')
        })
      },
      
    }
  }
  </script>