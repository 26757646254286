<template>
    <div>
      <div>
       <b-button @click="confirmShilling" size="sm" variant="danger">
        Manual Shilling
       </b-button>
       <div>
            
       </div>
      </div>
    </div>
  </template>
  
  <script>
    export default {
        props: ['value'],
      data() {
        return {
          
        }
      },
      computed: {
      
      },
      methods: {
        confirmShilling() {
        
          this.$bvModal.msgBoxConfirm(`Confirm Shilling ${this.value} tweets?`)
            .then(value => {
              if (value) {
                this.$store.dispatch('twitter/manualShill')
      
              }
            })
            .catch(err => {
              console.log(err);
            })
        },
      }
    }
  </script>