<template>
    <div>
        <b-button variant="primary" class="mt-2" v-b-modal.modal11-prevent-closing size="sm"><b-icon
                icon="plus" /><b-icon
                icon="stopwatch" /></b-button>

        <b-modal size="xl" id="modal11-prevent-closing" ref="modal11" title="Automate Tweets" @show="resetModal11"
            @hidden="resetModal11" @ok="handleOk11">

            <b-row>
                <b-col lg="6" md="6" sm="6" xs="6">

                    <div class="form-group mt-4">
                        <label for="attributes"><b>Tweet Content</b></label>
                        <div>Text</div>
                        <AttributesModal @logAttributes="logAttributes"></AttributesModal>
                    </div>

                    <div v-if="attributes.length > 0">
                        <b-row>
                            <b-col cols="6" v-for="(attribute, index) in attributes" :key="index">
                                <b-card bg-variant="light">
                                    <b-button size="sm" variant="light" @click="rmAttribute(index)">
                                        <b-icon icon="trash"></b-icon>
                                    </b-button>
                                    <div class="text-center add">
                                        <p>{{ attribute.value }}</p>
                                    </div>
                                </b-card>
                            </b-col>

                        </b-row>
                    </div>



                </b-col>
                <b-col lg="6" md="6" sm="6" xs="6">

                    <div class="form-group mt-2">
                        <TimeModal @logTime="logTime"> </TimeModal>
                    </div>

                    <div v-if="time.length > 0">


                        <div class="text-center add" v-for="(tm, index) in time" :key="index">
                            {{ tm.date }} {{ tm.time }}

                            <b-button size="sm" variant="light" @click="rmTime(index)">
                                <b-icon icon="trash"></b-icon>
                            </b-button>
                        </div>

                    </div>

                    <div class="form-group mt-2">
                        <div><b>Image content</b></div>
                        <div>The main asset of your Tweet, can be <b>JPEG, PNG or GIF</b></div>
                        <b-button variant="primary" @click="onPickFile">Upload image </b-button>
                        <input type="file" style="display: none" ref="fileInput" accept="image/*" @change="onFilePicked">

                        <img :src="imageUrl" height="200">

                        <div class="add">{{ imgUrl }}</div>

                        <div class="mt-3" v-for="(md, index) in media_ids" :key="index">
                            {{ index }} {{ md }}

                        </div>

                    </div>
                </b-col>
            </b-row>

        </b-modal>
    </div>
</template>
  
<script>

import AttributesModal from './AttributesModal.vue';
import TimeModal from './TimeModal.vue';

export default {
    data() {
        return {
            loading: false,
            message: '',
            imageUrl: '',
            image: '',
            media_ids: [],
            attributes: [],
            time: [],
        }
    },
    components: {
        AttributesModal,
        TimeModal
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
        imgUrl() {
            return this.$store.getters['twitter/loadedImgUrl'];
        }

    },
    mounted() {
       
    },
    methods: {


        resetModal11() {

        },
        handleOk11(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.handleSubmit11()
        },
        handleSubmit11() {
            // Exit when the form isn't valid
            this.loading = true;


            if (!this.imgUrl || this.attributes.length === 0 || this.time.length === 0) {
                this.loading = false;
                console.log('error')
                this.$store.dispatch('nft/setMessage', 'Please fill in all fields.')
                return;
            }
            const progData = {
                imageUrl: "https://ipfs.io/ipfs/" + this.imgUrl,
                //image: this.imgUrl,
                time: this.time,
                attributes: this.attributes,
            }
            this.$store.dispatch('twitter/setAutoTweet', progData)
            this.loading = false;

            this.$nextTick(() => {
                this.$bvModal.hide('modal11-prevent-closing')
            })
        },
        onPickFile() {
            this.$refs.fileInput.click()
        },
        onFilePicked(event) {
            const files = event.target.files
            let filename = files[0].name
            if (filename.lastIndexOf('.') <= 0) {
                return alert('Please add a valid file!')
            }
            const fileReader = new FileReader()
            fileReader.addEventListener('load', () => {
                this.imageUrl = fileReader.result
            })
            fileReader.readAsDataURL(files[0])
            this.image = files[0]
            this.$store.dispatch('twitter/uploadImg', files[0])

        },
        logTime(value) {
            this.time.push(value)
        },
        rmTime: function (id) {
            this.time.splice(id, 1)
        },
        logAttributes(value) {
            this.attributes.push(value)
        },
        rmAttribute: function (id) {
            this.attributes.splice(id, 1)
        },
    }
}
</script>
<style scoped>
.add {
    font-size: 10pt;
}

.form-group {
    font-size: 10pt;
}
</style>