<template>
  <div class="main">

    <div class="home">


      <div class="container">
        <b-row>

          <b-col lg="6" md="12" sm="12" xs="12">
            <b-row align-v="center">
              <b-col cols="6">


                <b-img src="/earth.png" width="200" center></b-img>

              </b-col>
              <b-col cols="6">
                <div class="cadre">
                  <h1 class="hd">I/O</h1>
                  <div>Headless Twitter Management</div>
                </div>
              </b-col>

            </b-row>
            <b-row>
              <b-col cols="6">
                <h4>Shilling System</h4>
                <h4>AI Processing</h4>
                <h4>Account Overwatch</h4>
              </b-col>
              <b-col cols="6">

              </b-col>

            </b-row>
          </b-col>
          <b-col lg="6" md="12" sm="12" xs="12">
            <b-row>
              <b-col cols="6">

              </b-col>
              <b-col cols="6">
                
                <b-button-group v-if="!currentUser">
                  <b-button variant="nature3" size="sm" @click.prevent="web3Login">WEB3</b-button>
                  <b-button variant="nature1" size="sm" :to="'/register'"><b-icon icon="person-plus-fill" /></b-button>
                  <b-button variant="nature2" size="sm" :to="'/login'"><b-icon icon="person-fill" /></b-button>
                 
                </b-button-group>

                <b-button-group v-else>
                  <b-button variant="nature3" size="sm" :to="'/hub'">Hub</b-button>
                  <b-button variant="nature3" size="sm" :to="'/profile'">Profile</b-button>
                </b-button-group>

              </b-col>
            </b-row>
          </b-col>
        </b-row>



      </div>


    </div>

  </div>
</template>

<script>


export default {
  name: 'Home',
  data() {
    return {
      content: ''
    };
  },
  components: {
  },
  metaInfo() {
    return {
      title: "I/O | Headless Twitter",
      meta: [
        { vmid: 'description', name: 'description', content: 'Headless Twitter' },
        { vmid: 'og:title', property: 'og:title', content: "I/O | Headless Twitter" },
        { vmid: 'og:site_name', property: 'og:site_name', content: 'io.philmo.ch' },
        { vmid: 'og:type', property: 'og:type', content: 'website' },
        { name: 'robots', content: 'index,follow' }
      ]
    }
  },
  mounted() {
    this.$store.commit('nft/setMessage', "Headless Twitter Management");
  },
  methods: {
    whitelistAddress: function (address) {
      const data = {
        address: address
      }
      this.$store.dispatch('nft/whitelistAddress', data)

    },
    connectWallet: function() {
      this.$store.dispatch('nft/connectWallet');
    },
    web3Login: function() {
      // check if walletaddress is set or call connectWallet
      if (this.walletAddress === '') {
        this.connectWallet();
      }
      const userObj = {
        address: this.walletAddress
      };
      // dispatch login passing walletaddress
      this.$store.dispatch('auth/web3Login', userObj);
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    walletAddress () {
      return this.$store.getters['nft/loadedWallet'];
    },
    wallet () {
      if (this.walletAddress === '') {
        return 'Wallet'
      } else {
        return String(this.walletAddress).substring(0, 4) + "..." + String(this.walletAddress).substring(40);
      }
    },
  }
};
</script>
<style scoped>
.spacer {
  height: 60px;
}

a {
  color: inherit;
}

a:hover {
  color: inherit;
  text-decoration: none;
}


.add {
  font-size: 10pt;
}

.add2 {

  font-size: 10pt;
}

pre code {
  display: block;
}

.poly {
  color: #8247e5;
}

.text2 {
  color: #d3d3d3;
}

.meta {
  color: #15e0b9;

}

.fuchsia {
  color: #ea3c4c;
}

.ether {
  color: #ffffff;
}

.eth {
  color: #3c1dcb;
}

.type {
  font-weight: 200;
}

.element1 {
  transition: all 0.2s ease-in-out;
  font-weight: 400;
  font-size: calc(1.5rem + 2vw);
}

.element1:hover {
  scale: 1.4;
}

.heading {

  background-color: #123676;
  padding-top: 30px;

}

.block {
  position: relative;
  background: linear-gradient(to bottom, #1DE9B6, #f2e9ba);
  display: flex;

}

.cadre {
  width: auto;
  height: auto;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 20px;
}

.hd {
  color: #ffffff;
  font-size: calc(1.5rem + 2vw);
  font-weight: 400;
}

.block1 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.block2 {
  background-color: #f8f9fa;
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: justify;
}

.block22 {
  background-color: #f8f9fa;
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: justify;
}

.block3 {
  background-color: #f8f9fa;
  padding-top: 60px;
  display: block;
}

.block4 {
  background: linear-gradient(to bottom, #f2e9ba, #f8f9fa);
  padding-top: 60px;
  padding-bottom: 60px;
  display: block;
}

.blockintro {
  background-color: #f8f9fa;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.block5 {
  position: relative;
  background-color: #69F0AE;

}


.block7 {
  background-color: #f8f9fa;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
}


.home {

  top: 0;
  width: 100%;
  min-height: calc(100vh - 70px);
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-bottom: 80px;
  background: linear-gradient(to bottom, #7462f9, #2058d3);

}




.main {
  overflow-x: hidden;
  width: 100%;
  height: 100%;
}

pre code {
  display: block;
  white-space: pre-wrap;
  font-size: 8pt;
  background-color: #e7ebea;
  padding: 10px;
}
</style>