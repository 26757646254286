<template>
    <div>
        <b-button variant="dark" class="mt-2" v-b-modal.modal9-prevent-closing size="sm"><b-icon icon="twitter" />Credentials</b-button>

        <b-modal size="xl" id="modal9-prevent-closing" ref="modal9" title="Query tweets" @show="resetModal9"
            @hidden="resetModal9" @ok="handleOk9">

            <b-row>
                <b-col lg="6" md="6" sm="6" xs="6">
                    <div class="form-group">
                        <label for="username">User ID</label>
                        <input v-model="currentUser.id" disabled type="text" class="form-control" name="artist" />
                    </div>

                    <div class="form-group">
                        <label for="username">Username</label>
                        <input v-model="currentUser.username" disabled type="text" class="form-control" name="artist" />
                    </div>

                    <div class="form-group">
                        <label for="username">Address</label>
                        <input v-model="currentUser.address" disabled type="text" class="form-control" name="artist" />
                    </div>

                </b-col>
                <b-col lg="6" md="6" sm="6" xs="6">

                    <div class="form-group">
                        <label for="at">Access Token</label>
                        <input v-model="access_token" v-validate="'required'" type="text" class="form-control"
                            name="name" />
                    </div>

                    <div class="form-group">
                        <label for="ats">Access Token Secret</label>
                        <b-form-textarea id="description" v-model="access_token_secret" placeholder="..." rows="3"
                            max-rows="6"></b-form-textarea>
                    </div>

                    <div class="form-group">
                        <label for="apik">API Key</label>
                        <input v-model="api_key" type="text" class="form-control" name="artist" />
                    </div>

                    <div class="form-group">
                        <label for="apis">API Secret</label>
                        <input v-model="api_secret" type="text" class="form-control" name="website" />
                    </div>
                </b-col>
            </b-row>

        </b-modal>
    </div>
</template>
  
<script>

import AuthService from '../services/auth.service';

export default {
    data() {
        return {
            loading: false,
            message: '',
            access_token: '',
            access_token_secret: '',
            api_key: '',
            api_secret: ''
        }
    },
    components: {

    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
        imgUrl() {
            return this.$store.getters['twitter/loadedImgUrl'];
        }

    },
    mounted() {
        this.loadCredentials();
    },
    methods: {


        resetModal9() {

        },
        handleOk9(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.handleSubmit9()
        },
        handleSubmit9() {
            // Exit when the form isn't valid
            this.loading = true;


            if (!this.access_token || !this.access_token_secret ||
                !this.api_key || !this.api_secret) {
                this.loading = false;
                console.log('error')
                this.$store.dispatch('nft/setMessage', 'Please fill in all fields.')
                return;
            }
            const credentialsData = {
                access_token: this.access_token,
                access_token_secret: this.access_token_secret,
                api_key: this.api_key,
                api_secret: this.api_secret,
            }
            this.$store.dispatch('auth/saveCredentials', credentialsData)
            this.loading = false;

            this.$nextTick(() => {
                this.$bvModal.hide('modal9-prevent-closing')
            })
        },
        onPickFile() {
            this.$refs.fileInput.click()
        },
        onFilePicked(event) {
            const files = event.target.files
            let filename = files[0].name
            if (filename.lastIndexOf('.') <= 0) {
                return alert('Please add a valid file!')
            }
            const fileReader = new FileReader()
            fileReader.addEventListener('load', () => {
                this.imageUrl = fileReader.result
            })
            fileReader.readAsDataURL(files[0])
            this.image = files[0]
            this.$store.dispatch('twitter/uploadImg', files[0])

        },
        logAttributes(value) {
            this.attributes.push(value)
        },
        rmAttribute: function (id) {
            this.attributes.splice(id, 1)
        },
        loadCredentials: function () {
            AuthService.getCredentials().then(response => {
                this.access_token = response.access_token;
                this.access_token_secret = response.access_token_secret;
                this.api_key = response.consumer_key;
                this.api_secret = response.consumer_secret;
            }, error => {
                this.message =
                    (error.response && error.response) ||
                    error.message ||
                    error.toString();

                this.$store.dispatch('nft/setMessage', this.message)
            });
        }
    }
}
</script>
<style scoped>
.add {
    font-size: 10pt;
}

.form-group {
    font-size: 10pt;
}
</style>